import { Chart } from 'chart.js';
export function initializeChart(data, filter, chartType, title) {
    const numberFormatter = new Intl.NumberFormat('en-US'); // Number formatter for commas
    const labels = data.map((d) => d.state_name);
    let dataset = [];
    if (chartType === 'nurse') {
        dataset = data.map((d) => filter === 'nurse_hours'
            ? d.nurse_hours_day.reduce((a, b) => a + b.original, 0) / d.nurse_hours_day.length
            : d.nurse_turnover.reduce((a, b) => a + b.original, 0) / d.nurse_turnover.length);
    }
    else {
        dataset = data.map((d) => d[filter]?.length
            ? d[filter].reduce((a, b) => a + b.original, 0) / d[filter].length
            : 0);
    }
    const combined = labels.map((label, index) => ({
        label,
        value: dataset[index],
    }));
    combined.sort((a, b) => b.value - a.value);
    const sortedLabels = combined.map((item) => item.label);
    const sortedDataset = combined.map((item) => item.value);
    const ctx = document.getElementById('MainStatsChart');
    if (ctx) {
        const chartInstance = Chart.getChart(ctx);
        if (chartInstance) {
            chartInstance.destroy();
        }
        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: sortedLabels,
                datasets: [
                    {
                        label: `Annual ${filter.replace('annual_', '').replace('_', ' ')} cost $`,
                        data: sortedDataset,
                        backgroundColor: sortedLabels.map((label) => label.toLowerCase() === 'national' ? '#0077b6' : '#4fd1c5'),
                    },
                ],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                indexAxis: 'y', // Ensures the bars are horizontal
                plugins: {
                    legend: {
                        display: true,
                        position: 'bottom',
                        labels: {
                            font: {
                                size: 20,
                            },
                        },
                    },
                    title: {
                        display: true,
                        text: title,
                        font: {
                            size: 30,
                        },
                    },
                    tooltip: {
                        bodyFont: {
                            size: 18,
                        },
                        callbacks: {
                            label: function (tooltipItem) {
                                const dataIndex = tooltipItem.dataIndex;
                                const dataValue = data[dataIndex][filter];
                                const formattedValues = dataValue.map((d) => d.formatted);
                                return ` Annual ${filter.replace('annual_', '').replace('_', ' ')} cost $: ${formattedValues.join(', ')}`;
                            },
                        },
                    },
                    datalabels: {
                        color: '#000',
                        font: {
                            size: 20,
                        },
                        formatter: function (value) {
                            return numberFormatter.format(value); // Format the bar labels with commas
                        },
                    },
                },
                scales: {
                    x: {
                        beginAtZero: true,
                        ticks: {
                            font: {
                                size: 20,
                            },
                        },
                    },
                    y: {
                        ticks: {
                            font: {
                                size: 20,
                            },
                            callback: function (value, index) {
                                return sortedLabels[index]; // Display state names on the y-axis
                            },
                        },
                    },
                },
            },
        });
    }
}
