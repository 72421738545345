"use strict";
const planInfo = {
    'Starter': {
        details: 'Starter details: Perfect for small businesses just getting started with email marketing. Includes basic features to help you grow your audience.',
        contacts: 1000
    },
    'Standard': {
        details: 'Standard details: Plan pricing starts at 500 contacts. Select your audience size to calculate your price. The monthly email send limit for Standard plans is 12 times your maximum contact count.',
        contacts: 5000
    },
    'Premium': {
        details: 'Premium details: Unlock advanced features and higher limits. Ideal for growing businesses with larger audiences and more complex needs.',
        contacts: 25000
    },
    'Enterprise': {
        details: 'Enterprise details: Custom solutions for large organizations. Includes personalized support, advanced security features, and unlimited contacts.',
        contacts: 'Unlimited'
    }
};
document.addEventListener('DOMContentLoaded', () => {
    if (window.location.pathname === '/pricing_choice') {
        clickablePlanButtons();
    }
});
function clickablePlanButtons() {
    const planButtons = document.querySelectorAll('.plan-button');
    const planName = document.getElementById('plan-name');
    const planPrice = document.getElementById('plan-price');
    const planDetails = document.getElementById('plan-details');
    planButtons.forEach(button => {
        button.addEventListener('click', function () {
            const selectedPlan = this.dataset.plan;
            const selectedPrice = this.dataset.price;
            // Update active button
            planButtons.forEach(btn => btn.classList.remove('bg-blue-600', 'text-white'));
            this.classList.add('bg-blue-600', 'text-white');
            // Update plan name and price
            planName.textContent = `${selectedPlan} plan`;
            planPrice.textContent = `$${selectedPrice}`;
            // Update plan details
            const details = planInfo[selectedPlan].details;
            const contacts = planInfo[selectedPlan].contacts;
            planDetails.innerHTML = `
                <h3 class="text-base font-normal text-gray-700 dark:text-gray-300 mb-2">${selectedPlan} details:</h3>
                <p class="mb-4">${details}</p>
                <p>Maximum contacts: ${contacts}</p>
            `;
        });
    });
}
