import { Chart } from 'chart.js';
import { getChartConfig } from './chart_config';
export function initializeChart(data) {
    const canvas = getCanvasElement();
    const ctx = getCanvasContext(canvas);
    if (!ctx) {
        throw new Error('Failed to get a valid drawing context.');
    }
    const chartConfig = getChartConfig(data);
    new Chart(ctx, chartConfig);
    // canvas.style.backgroundColor = 'white';
}
function getCanvasElement() {
    const canvas = document.getElementById('StatsBarChart');
    if (!canvas) {
        throw new Error('Canvas element not found in the document');
    }
    return canvas;
}
function getCanvasContext(canvas) {
    return canvas.getContext('2d');
}
