"use strict";
document.addEventListener('DOMContentLoaded', () => {
    if (window.location.pathname === '/index' ||
        window.location.pathname === '/') {
        addScrollListener();
    }
});
function addScrollListener() {
    document.addEventListener('scroll', function () {
        const navbar = document.getElementById('home_nav');
        if (navbar) {
            if (window.scrollY > 50) {
                navbar.classList.add('hidden');
            }
            else {
                navbar.classList.remove('hidden');
            }
        }
    });
}
