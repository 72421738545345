import { Chart, BarController, CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title, } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { initializeChart } from './initialize_chart';
Chart.register(BarController, CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title, ChartDataLabels);
document.addEventListener('DOMContentLoaded', () => {
    if (window.location.pathname === '/stats') {
        fetchDataAndRenderChart();
    }
});
function fetchDataAndRenderChart() {
    const apiBaseUrl = window.location.origin;
    fetch(`${apiBaseUrl}/stats/data/abuse_rates`)
        .then((response) => {
        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }
        return response;
    })
        .then((response) => {
        return response.json();
    })
        .then(initializeChart)
        .catch(handleError);
}
function handleError(error) {
    console.error('Error loading or processing data:', error);
}
