// chartSettings.ts
const chartSettings = {
    // The URL path where the chart should be displayed
    chartPath: '/facility_detail',
    // The API endpoint for fetching chart data
    dataEndpoint: '/search/data/hstats',
    // The ID of the HTML element where the chart will be rendered
    chartContainerId: 'HospiceBarChart'
};
export default chartSettings;
