export function getChartConfig(data) {
    return {
        type: 'bar',
        data: {
            labels: data.labels,
            datasets: [
                {
                    label: 'Abuse Rates',
                    data: data.values,
                    backgroundColor: ['#f6ad55', '#4fd1c5', '#68d391', '#63b3ed'],
                },
            ],
        },
        options: getChartOptions(),
    };
}
function getChartOptions() {
    return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 10,
                    font: {
                        size: 18,
                    },
                },
            },
            x: {
                display: true,
                ticks: {
                    font: {
                        size: 18,
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
                labels: {
                    font: {
                        size: 20,
                    },
                },
            },
            title: {
                display: false,
                text: 'Abuse Rates',
                font: {
                    size: 30,
                },
                padding: {
                    top: 10,
                    bottom: 30,
                },
            },
            tooltip: {
                callbacks: {
                    label: tooltipLabelCallback(),
                },
                bodyFont: {
                    size: 18,
                },
            },
            datalabels: {
                color: '#000',
                formatter: (value) => formatRatio(value),
                font: {
                    size: 20,
                },
            },
        },
    };
}
function tooltipLabelCallback() {
    return function (tooltipItem) {
        const label = tooltipItem.label || '';
        const value = tooltipItem.raw;
        const ratio = formatRatio(value);
        return `${label}: ${ratio}`;
    };
}
function formatRatio(value) {
    return `1:${Math.round(value / 1)}`;
}
