import * as d3 from 'd3';
export function renderMap(svg, path, us, tooltip, stateData, selectedFacility) {
    svg.selectAll('*').remove(); // Clear the previous map
    // Get the data values for the selected facility
    const dataValues = stateData
        .map((state) => {
        let facilityData;
        if (selectedFacility === 'nurse_hours_day' ||
            selectedFacility === 'nurse_turnover') {
            facilityData = state[selectedFacility];
        }
        else {
            facilityData = state[`annual_${selectedFacility}`];
        }
        if (!facilityData ||
            (Array.isArray(facilityData) && facilityData.length === 0)) {
            return null; // Skip this state if no valid data
        }
        return {
            state_name: state.state_name,
            value: facilityData.reduce((a, b) => a + b.original, 0), // Sum the original values
            formattedValues: facilityData.map((d) => d.formatted).join(', '), // Join formatted values for display
        };
    })
        .filter((d) => d !== null); // Filter out states with no valid data
    // Get the values and filter out undefined values
    const values = dataValues
        .map((d) => d.value)
        .filter((value) => value !== undefined);
    const minValue = d3.min(values);
    const maxValue = d3.max(values);
    // Create a quantize scale with a cloned and reversed color range
    const colorScale = d3
        .scaleQuantize()
        .domain([minValue, maxValue])
        .range(d3.schemeRdYlGn[9].slice().reverse());
    svg
        .append('g')
        .selectAll('path')
        .data(us.features)
        .enter()
        .append('path')
        .attr('class', 'state transition-colors duration-300')
        .attr('d', path)
        .attr('fill', (d) => {
        const stateInfo = dataValues.find((state) => d.properties && state.state_name === d.properties.NAME);
        return stateInfo ? colorScale(stateInfo.value) : '#ccc';
    })
        .attr('stroke', '#fff')
        .attr('stroke-width', 1)
        .on('mouseover', function (event, d) {
        const stateInfo = stateData.find((state) => d.properties && state.state_name === d.properties.NAME);
        if (stateInfo) {
            d3.select(this).classed('text-blue-500', true);
            let facilityData;
            if (selectedFacility === 'nurse_hours_day' ||
                selectedFacility === 'nurse_turnover') {
                facilityData = stateInfo[selectedFacility];
            }
            else {
                facilityData = stateInfo[`annual_${selectedFacility}`];
            }
            const facilityName = selectedFacility
                .replace('_', ' ')
                .replace(/\b\w/g, (char) => char.toUpperCase());
            const facilityValues = facilityData.map((d) => d.formatted).join(', ');
            const divStrong = `<div><strong>${stateInfo.state_name}</strong></div>`;
            const divAnnual = `<div>Annual ${facilityName} Cost: $${facilityValues}</div>`;
            const tooltipContent = `<div>${divStrong}${divAnnual}</div>`;
            tooltip.style('visibility', 'visible').html(tooltipContent);
        }
    })
        .on('mousemove', function (event) {
        tooltip
            .style('top', `${event.pageY - 10}px`)
            .style('left', `${event.pageX + 10}px`);
    })
        .on('mouseout', function () {
        d3.select(this).classed('text-blue-500', false);
        tooltip.style('visibility', 'hidden');
    });
}
