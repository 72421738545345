"use strict";
document.addEventListener('DOMContentLoaded', () => {
    if (window.location.pathname === '/search') {
        const menuButton = document.getElementById('menu-button');
        if (!menuButton) {
            console.error('Sort button not found!');
            return;
        }
        const dropdownMenu = menuButton.parentElement
            ?.nextElementSibling;
        if (!dropdownMenu) {
            console.error('Dropdown menu not found!');
            return;
        }
        menuButton.addEventListener('click', () => {
            const isExpanded = menuButton.getAttribute('aria-expanded') === 'true';
            menuButton.setAttribute('aria-expanded', String(!isExpanded));
            // Toggle TailwindCSS 'hidden' class to show/hide the dropdown
            dropdownMenu.classList.toggle('hidden');
            console.log('Dropdown visibility toggled:', !dropdownMenu.classList.contains('hidden'));
        });
    }
});
