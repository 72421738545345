import { initializeChart } from './chartInitializer';
import chartSettings from './chartSettings';
/**
 * Fetches data from the API and initializes the chart with the retrieved data.
 * If an error occurs during fetching or processing, it's caught and logged.
 */
export function fetchDataAndRenderChart() {
    const apiBaseUrl = window.location.origin;
    // Fetch data from the API using the configured endpoint
    fetch(`${apiBaseUrl}${chartSettings.dataEndpoint}`)
        .then((response) => {
        if (!response.ok) {
            throw new Error('Network response was not ok: ' + response.statusText);
        }
        return response;
    })
        .then((response) => {
        // Parse the JSON response
        return response.json();
    })
        .then(initializeChart) // Initialize the chart with the fetched data
        .catch(handleError); // Handle any errors that occurred during the process
}
/**
 * Handles and logs any errors that occur during data fetching or chart initialization.
 * @param error - The error object caught in the promise chain
 */
function handleError(error) {
    console.error('Error loading or processing data:', error);
}
