"use strict";
document.addEventListener("DOMContentLoaded", () => {
    const lazyBackgrounds = document.querySelectorAll(".lazy-bg");
    if ("IntersectionObserver" in window) {
        const lazyBackgroundObserver = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const lazyBackground = entry.target;
                    lazyBackground.style.backgroundImage = `url('${lazyBackground.getAttribute('data-bg-url')}')`;
                    lazyBackgroundObserver.unobserve(lazyBackground);
                }
            });
        });
        lazyBackgrounds.forEach((lazyBackground) => {
            lazyBackgroundObserver.observe(lazyBackground);
        });
    }
    else {
        // Fallback for browsers that do not support IntersectionObserver
        const lazyLoad = () => {
            lazyBackgrounds.forEach((lazyBackground) => {
                if (lazyBackground.getBoundingClientRect().top <= window.innerHeight && lazyBackground.getBoundingClientRect().bottom >= 0) {
                    lazyBackground.style.backgroundImage = `url('${lazyBackground.getAttribute('data-bg-url')}')`;
                }
            });
        };
        document.addEventListener("scroll", lazyLoad);
        window.addEventListener("resize", lazyLoad);
        window.addEventListener("orientationchange", lazyLoad);
    }
});
