import { Chart, BarController, CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title, } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
/**
 * Registers all necessary Chart.js components and plugins.
 * This function must be called before creating any charts.
 *
 * Why this is needed:
 * 1. Performance: Chart.js uses a modular structure. Registering only the
 *    components we need reduces the overall bundle size and improves performance.
 * 2. Customization: By explicitly registering components, we have fine-grained
 *    control over what features are available in our charts.
 * 3. Plugin integration: Custom plugins like ChartDataLabels need to be
 *    registered to extend Chart.js functionality.
 *
 */
export function registerChartComponents() {
    Chart.register(BarController, // Enables bar charts
    CategoryScale, // Used for the x-axis in this bar chart
    LinearScale, // Used for the y-axis in this bar chart
    BarElement, // Renders the bars in the chart
    Tooltip, // Enables tooltips on hover
    Legend, // Enables the chart legend
    Title, // Enables chart title (if used)
    ChartDataLabels // Plugin for adding data labels on the bars
    );
    // If additional chart types or features are needed in the future,
    // import and register them here. For example:
    // import { LineController, PointElement } from 'chart.js';
    // Chart.register(LineController, PointElement);
}
