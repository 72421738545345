import * as d3 from 'd3';
import { createPath } from './createPath';
import { createTooltip } from './createTooltip';
import { fetchStateData as fetchFacilityData } from './fetchStateData';
import { fetchChartData } from '../barChart/Genworth/fetch_data';
import { initializeChart } from '../barChart/Genworth/initialize_chart';
import { renderMap } from './renderMap';
import { renderLegend } from './renderLegend';
document.addEventListener('DOMContentLoaded', async () => {
    if (window.location.pathname === '/stats') {
        await fetchDataAndRenderMap();
        const data = await fetchChartData('incare'); // Fetch initial chart data
        initializeChart(data, 'annual_homemaker', 'incare', 'In-Care Statistics'); // Initialize chart with default values
    }
});
async function fetchDataAndRenderMap() {
    const mapSvg = d3.select('#map');
    const legendSvg = d3.select('#legend');
    const path = createPath();
    const tooltip = createTooltip();
    let stateData = await fetchFacilityData('incare');
    const facilityTypeSelect = document.getElementById('facility-type-select');
    const facilitySelect = document.getElementById('facility-select');
    let selectedFacilityType = facilityTypeSelect.value;
    let selectedFacility = facilitySelect.value;
    const updateFacilityOptions = () => {
        const options = {
            incare: ['homemaker', 'healthaid'],
            community: ['day_healthcare', 'assisted_living'],
            nursinghome: ['semi_private', 'private'],
            nurse: ['nurse_hours_day', 'nurse_turnover'],
        };
        facilitySelect.innerHTML = '';
        options[selectedFacilityType].forEach((facility) => {
            const option = document.createElement('option');
            option.value = facility;
            option.text = facility
                .replace('_', ' ')
                .replace(/\b\w/g, (char) => char.toUpperCase());
            facilitySelect.appendChild(option);
        });
        selectedFacility = facilitySelect.value;
    };
    const updateMapAndChart = async () => {
        try {
            const us = await d3.json('/stats/static/json/gz_2010_us_040_00_500k.json');
            if (!us) {
                throw new Error('Failed to load GeoJSON data');
            }
            const dataValues = stateData
                .map((state) => {
                let facilityData;
                if (selectedFacility === 'nurse_hours_day' ||
                    selectedFacility === 'nurse_turnover') {
                    facilityData = state[selectedFacility];
                }
                else {
                    facilityData = state[`annual_${selectedFacility}`];
                }
                if (!facilityData ||
                    (Array.isArray(facilityData) && facilityData.length === 0)) {
                    return null; // Skip this state if no valid data
                }
                return {
                    state_name: state.state_name,
                    value: facilityData.reduce((a, b) => a + b.original, 0), // Sum the original values
                    formattedValues: facilityData.map((d) => d.formatted).join(', '), // Join formatted values for display
                };
            })
                .filter((d) => d !== null); // Filter out states with no valid data
            const values = dataValues.map((d) => d.value).sort((a, b) => a - b);
            renderMap(mapSvg, path, us, tooltip, stateData, selectedFacility);
            renderLegend(legendSvg, values);
            const data = await fetchChartData(selectedFacilityType);
            initializeChart(data, `annual_${selectedFacility}`, selectedFacilityType, getTitleForChartType(selectedFacilityType));
        }
        catch (error) {
            console.error('Error loading or parsing GeoJSON data:', error);
        }
    };
    facilityTypeSelect.addEventListener('change', async () => {
        selectedFacilityType = facilityTypeSelect.value;
        stateData = await fetchFacilityData(selectedFacilityType);
        updateFacilityOptions();
        updateMapAndChart();
    });
    facilitySelect.addEventListener('change', () => {
        selectedFacility = facilitySelect.value;
        updateMapAndChart();
    });
    updateFacilityOptions();
    updateMapAndChart();
}
function getTitleForChartType(chartType) {
    const titles = {
        nurse: 'Nurse Statistics',
        incare: 'In-Care Statistics',
        community: 'Community Care Statistics',
        nursinghome: 'Nursing Home Statistics',
    };
    return titles[chartType];
}
