import { calculateTotal } from './calculate_total';
export function getChartConfig(data, total) {
    return {
        type: 'doughnut',
        data: {
            labels: data.labels,
            datasets: [
                {
                    label: 'Types of Abuse',
                    data: data.values,
                    backgroundColor: ['#f6ad55', '#4fd1c5', '#68d391', '#63b3ed'],
                },
            ],
        },
        options: getChartOptions(total),
    };
}
function getChartOptions(total) {
    return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    font: {
                        size: 20,
                    },
                },
            },
            title: {
                display: false,
                text: 'Types of Abuse',
                font: {
                    size: 30,
                },
                padding: {
                    top: 10,
                    bottom: 30,
                },
            },
            tooltip: {
                callbacks: {
                    label: tooltipLabelCallback(),
                },
                bodyFont: {
                    size: 18,
                },
            },
            datalabels: {
                color: '#000',
                formatter: (value) => formatPercentage(value, total),
                font: {
                    size: 20,
                },
            },
        },
    };
}
function tooltipLabelCallback() {
    return function (tooltipItem) {
        const label = tooltipItem.label || '';
        const value = tooltipItem.raw;
        const percentage = formatPercentage(value, calculateTotal(tooltipItem.dataset.data));
        return `${label}: ${percentage}`;
    };
}
function formatPercentage(value, total) {
    const percentage = ((value / total) * 100).toFixed(2) + '%';
    return percentage;
}
