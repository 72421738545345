import * as d3 from 'd3';
export function renderLegend(svg, values) {
    svg.selectAll('*').remove(); // Clear previous legend
    const defs = svg.append('defs');
    const linearGradient = defs
        .append('linearGradient')
        .attr('id', 'linear-gradient');
    // Ensure there are no undefined values
    const filteredValues = values.filter((value) => value !== undefined);
    // Create a quantile scale
    const colorScale = d3.scaleSequentialQuantile(filteredValues, d3.interpolateRdYlGn);
    const stops = d3.range(0, 1.1, 0.1).map((t) => {
        return {
            offset: `${t * 100}%`,
            color: colorScale(d3.quantile(filteredValues, 1 - t)), // Invert the quantile for reversed color scale
        };
    });
    linearGradient
        .selectAll('stop')
        .data(stops)
        .enter()
        .append('stop')
        .attr('offset', (d) => d.offset)
        .attr('stop-color', (d) => d.color);
    const legendWidth = 300;
    const legendHeight = 20;
    svg
        .append('rect')
        .attr('width', legendWidth)
        .attr('height', legendHeight)
        .style('fill', 'url(#linear-gradient)')
        .attr('transform', `translate(${svg.attr('width') / 2 - legendWidth / 2}, 0)`);
    const x = d3
        .scaleLinear()
        .domain([d3.min(filteredValues) || 0, d3.max(filteredValues) || 0])
        .range([0, legendWidth]);
    const xAxis = d3
        .axisBottom(x)
        .ticks(5)
        .tickSize(-legendHeight)
        .tickPadding(10);
    svg
        .append('g')
        .attr('class', 'legend-axis')
        .attr('transform', `translate(${svg.attr('width') / 2 - legendWidth / 2}, 20)`)
        .call(xAxis)
        .select('.domain')
        .remove();
}
