"use strict";
document.addEventListener('DOMContentLoaded', () => {
    if (window.location.pathname === '/index' ||
        window.location.pathname === '/') {
        const modal = document.getElementById('exampleModal');
        const closeModalButton = document.getElementById('closeModal');
        const showModal = () => {
            modal.classList.remove('hidden');
        };
        const hideModal = () => {
            modal.classList.add('hidden');
        };
        closeModalButton.addEventListener('click', hideModal);
        window.addEventListener('click', (event) => {
            if (event.target === modal) {
                hideModal();
            }
        });
        // Attach showModal function to window object
        window.showModal = showModal;
    }
});
