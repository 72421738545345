import { ApplicationInsights } from '@microsoft/applicationinsights-web';
const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey: '956ba23a-d00c-4f82-a4e7-1443d093a661',
        enableAutoRouteTracking: true,
        enableRequestHeaderTracking: true,
        enableDebug: true,
        disableExceptionTracking: true,
    },
});
appInsights.loadAppInsights();
appInsights.trackPageView();
