"use strict";
document.addEventListener('DOMContentLoaded', () => {
    const toggleButtons = document.querySelectorAll('button[aria-expanded]');
    toggleButtons.forEach((button) => {
        button.addEventListener('click', () => {
            // Directly manage aria-expanded attribute
            const expanded = button.getAttribute('aria-expanded') === 'true';
            const controls = button.getAttribute('aria-controls');
            if (controls) {
                const section = document.getElementById(controls);
                if (section) {
                    button.setAttribute('aria-expanded', expanded ? 'false' : 'true');
                    section.style.display =
                        section.style.display === 'none' ? 'block' : 'none';
                    const icon = button.querySelector('svg');
                    if (icon) {
                        icon.innerHTML = expanded
                            ? '<path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />'
                            : '<path fill-rule="evenodd" d="M4 10a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H4.75A.75.75 0 014 10z" clip-rule="evenodd" />';
                    }
                }
            }
        });
    });
});
