/**
 * Generates the chart configuration based on the provided data.
 * @param data - An object containing labels and values for the chart
 * @returns A ChartConfiguration object for a bar chart
 */
export function getChartConfig(data) {
    return {
        // Chart.js supports various chart types such as 'line', 'pie', 'doughnut', 'radar', etc.
        // https://www.chartjs.org/docs/latest/charts/bar.html
        type: 'pie',
        data: {
            labels: data.labels,
            datasets: [
                {
                    label: 'Hospice Patients',
                    data: data.values,
                    backgroundColor: ['#755b44', '#6b8e23', '#2d6a4f', '#d3e4da', '#e3cca3', '#b5651d'],
                },
            ],
        },
        options: getChartOptions(),
    };
}
/**
 * Generates the chart options for customizing the chart's appearance and behavior.
 * @returns A ChartOptions object for a bar chart
 */
function getChartOptions() {
    return {
        responsive: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            },
        },
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'center',
                labels: {
                    font: { size: 16 },
                    color: '#333',
                    padding: 20,
                },
            },
            title: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: tooltipLabelCallback(),
                },
                bodyFont: { size: 18 },
            },
            datalabels: {
                color: (context) => {
                    const backgroundColor = context.dataset?.backgroundColor;
                    const bgColor = backgroundColor ? backgroundColor[context.dataIndex] : '#000';
                    return getContrastYIQ(bgColor);
                },
                formatter: (value) => formatRatio(value),
                font: { size: 20 },
                borderColor: '#fff',
                borderWidth: 2,
            },
        },
    };
}
// Helper function to determine contrasting text color
function getContrastYIQ(hexcolor) {
    hexcolor = hexcolor.replace('#', '');
    const r = parseInt(hexcolor.substring(0, 2), 16);
    const g = parseInt(hexcolor.substring(2, 4), 16);
    const b = parseInt(hexcolor.substring(4, 6), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? '#000' : '#fff'; // Returns black or white
}
/**
 * Creates a callback function for customizing tooltip labels.
 * @returns A function that formats the tooltip label
 */
function tooltipLabelCallback() {
    return function (tooltipItem) {
        const label = tooltipItem.label || '';
        const value = tooltipItem.parsed;
        const ratio = formatRatio(value);
        return `${label}: ${ratio}`;
    };
}
/**
 * Formats a number into a ratio string.
 * @param value - The number to be formatted
 * @returns A string representing the ratio (e.g., "1:5")
 */
function formatRatio(value) {
    return `${Math.round(value / 1)}%`;
}
