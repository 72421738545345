// chartSettings.ts
const chartSettings = {
    // The URL path where the chart should be displayed
    chartPath: '/stats',
    // The API endpoint for fetching chart data
    dataEndpoint: '/stats/data/chart_demo',
    // The ID of the HTML element where the chart will be rendered
    chartContainerId: 'DemoBarChart'
};
export default chartSettings;
