/**
 * Generates the chart configuration based on the provided data.
 * @param data - An object containing labels and values for the chart
 * @returns A ChartConfiguration object for a bar chart
 */
export function getChartConfig(data) {
    return {
        // Chart.js supports various chart types such as 'line', 'pie', 'doughnut', 'radar', etc.
        // https://www.chartjs.org/docs/latest/charts/bar.html
        type: 'bar',
        data: {
            labels: data.labels,
            datasets: [
                {
                    label: 'Patient Percentages',
                    data: data.values,
                    backgroundColor: ['#755b44', '#6b8e23', '#2d6a4f', '#d3e4da', '#e3cca3'],
                },
            ],
        },
        options: getChartOptions(),
    };
}
/**
 * Generates the chart options for customizing the chart's appearance and behavior.
 * @returns A ChartOptions object for a bar chart
 */
function getChartOptions() {
    return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                ticks: {
                    stepSize: 10,
                    font: { size: 18 },
                },
            },
            x: {
                display: true,
                ticks: { font: { size: 18 } },
            },
        },
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
                labels: { font: { size: 20 } },
            },
            tooltip: {
                callbacks: {
                    label: tooltipLabelCallback(),
                },
                bodyFont: { size: 18 },
            },
            datalabels: {
                color: '#000',
                formatter: (value) => formatRatio(value),
                font: { size: 20 },
            },
        },
    };
}
/**
 * Creates a callback function for customizing tooltip labels.
 * @returns A function that formats the tooltip label
 */
function tooltipLabelCallback() {
    return function (tooltipItem) {
        const label = tooltipItem.label || '';
        const value = tooltipItem.parsed.y;
        const ratio = formatRatio(value);
        return `${label}: ${ratio}`;
    };
}
/**
 * Formats a number into a ratio string.
 * @param value - The number to be formatted
 * @returns A string representing the ratio (e.g., "1:5")
 */
function formatRatio(value) {
    // return `1:${Math.round(value / 1)}`;
    return `${value}%`;
}
