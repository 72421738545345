"use strict";
document.addEventListener('DOMContentLoaded', () => {
    const hamburgerButton = document.getElementById('hamburger-button');
    const mobileMenu = document.getElementById('mobile-menu');
    const closeButton = document.getElementById('close-button');
    if (hamburgerButton && mobileMenu && closeButton) {
        hamburgerButton.addEventListener('click', () => {
            hamburgerButton.classList.add('hidden'); // Hide the hamburger button when the menu opens
            mobileMenu.classList.remove('translate-x-full');
            mobileMenu.classList.remove('hidden');
        });
        closeButton.addEventListener('click', () => {
            mobileMenu.classList.add('translate-x-full');
            mobileMenu.addEventListener('transitionend', () => {
                mobileMenu.classList.add('hidden');
                hamburgerButton.classList.remove('hidden'); // Show the hamburger button when the menu closes
            }, { once: true });
        });
    }
    else {
        console.error('Hamburger button, mobile menu, or close button not found');
    }
});
