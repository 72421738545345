import { Chart } from 'chart.js';
import { getChartConfig } from './chartConfig';
import chartSettings from './chartSettings';
/**
 * Initializes the chart with the provided data.
 * @param data - An object containing labels and values for the chart
 */
export function initializeChart(data) {
    const canvas = getCanvasElement();
    const ctx = getCanvasContext(canvas);
    if (!ctx) {
        throw new Error('Failed to get a valid drawing context.');
    }
    // Get the chart configuration based on the provided data
    const chartConfig = getChartConfig(data);
    // Create a new Chart instance
    new Chart(ctx, chartConfig);
}
/**
 * Retrieves the canvas element from the DOM.
 * @returns The canvas element for the chart
 * @throws Error if the canvas element is not found
 */
function getCanvasElement() {
    const canvas = document.getElementById(chartSettings.chartContainerId);
    if (!canvas) {
        throw new Error(`Canvas element with id '${chartSettings.chartContainerId}' not found in the document`);
    }
    return canvas;
}
/**
 * Gets the 2D rendering context from the canvas element.
 * @param canvas - The canvas element to get the context from
 * @returns The 2D rendering context, or null if not available
 */
function getCanvasContext(canvas) {
    return canvas.getContext('2d');
}
