import { registerChartComponents } from './chartRegistration';
import { fetchDataAndRenderChart } from './dataFetcher';
import chartSettings from './chartSettings';
// Wait for the DOM to be fully loaded before initializing the chart
document.addEventListener('DOMContentLoaded', () => {
    // Check if we're on the configured chart path
    if (window.location.pathname === chartSettings.chartPath) {
        // Register all required Chart.js components
        registerChartComponents();
        // Fetch data and render the chart
        fetchDataAndRenderChart();
    }
});
