import { Chart, BarController, CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title, } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { fetchChartData } from './fetch_data';
import { initializeChart } from './initialize_chart';
Chart.register(BarController, CategoryScale, LinearScale, BarElement, Tooltip, Legend, Title, ChartDataLabels);
document.addEventListener('DOMContentLoaded', () => {
    if (window.location.pathname === '/stats') {
        initialize();
    }
});
async function initialize() {
    try {
        const chartType = 'incare'; // default chart type
        const filter = 'annual_homemaker'; // default filter
        const data = await fetchChartData(chartType);
        console.log('Fetched Data:', data); // Log the fetched data
        initializeChart(data, filter, chartType, 'In-Care Statistics');
        // Event listeners for dropdown changes
        document
            .getElementById('facility-type-select')
            ?.addEventListener('change', async (event) => {
            const selectedChartType = event.target.value;
            const filter = getFilterForChartType(selectedChartType);
            const data = await fetchChartData(selectedChartType);
            console.log('Fetched Data:', data); // Log the fetched data
            initializeChart(data, filter, selectedChartType, getTitleForChartType(selectedChartType));
        });
        document
            .getElementById('facility-select')
            ?.addEventListener('change', async (event) => {
            const selectedFacility = event.target.value;
            const data = await fetchChartData(chartType);
            console.log('Fetched Data:', data); // Log the fetched data
            initializeChart(data, `annual_${selectedFacility}`, chartType, 'In-Care Statistics');
        });
    }
    catch (error) {
        console.error('Error during initialization:', error);
    }
}
function getFilterForChartType(chartType) {
    const defaultFilters = {
        nurse: 'nurse_hours',
        incare: 'annual_homemaker',
        community: 'annual_day_healthcare',
        nursinghome: 'annual_semi_private',
    };
    return defaultFilters[chartType];
}
function getTitleForChartType(chartType) {
    const titles = {
        nurse: 'Nurse Statistics',
        incare: 'In-Care Statistics',
        community: 'Community Care Statistics',
        nursinghome: 'Nursing Home Statistics',
    };
    return titles[chartType];
}
